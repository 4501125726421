//=======================================================================
// UNIVERSAL
//=======================================================================

html {
    // overflow-y: scroll; // scrollbar fix (prevent ugly left pull)
}

body {
    overflow-x: hidden;
    font-size: 18px;
    color: #171812;

    * {
        font-family: 'Poppins', sans-serif $i;
    }

    &.rtl {
        * {
            font-family: 'Open Sans Hebrew', sans-serif $i;
        }

        .fa {
            font-family: FontAwesome $i;
        }
    }

    &.fluid-body {
        #main-component {
            >.container {
                max-width: 100%;
                padding: 0;

                .normal-container {
                    width: 100%;
                    max-width: 1140px;
                    padding: 0px 15px;
                    margin: 0 auto;
                }
            }
        }
    }

    ul.breadcrumb {
        padding: 8px 15px;
        background-color: transparent;
        border-radius: 0px;
        margin: 4px 0px;

        >li {
            +li {
                &:before {
                    color: #171812;
                    display: inline-block;
                    font-size: 12px;
                    width: 14px;
                }
            }
        }

        li {
            * {
                color: #171812;
                font-size: 16px;
            }

            a {
                text-decoration: underline;
            }

            .divider {
                display: none;
            }

            &[itemprop="itemListElement"] {

                &:first-of-type,
                &:nth-child(2) {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

li:hover>a,
li>a,
#logo,
.fixed #logo,
.preloader,
#logo img,
.togglers h3:before,
ul.nav.menu>li a:after,
#ituran,
.id-101 .services .col-sm-4 a,
.border-image,
.product-info,
.hover-cont,
.relateditems a img {
    -webkit-transition: all 300ms ease-in-out $i;
    -moz-transition: all 300ms ease-in-out $i;
    -ms-transition: all 300ms ease-in-out $i;
    -o-transition: all 300ms ease-in-out $i;
    transition: all 300ms ease-in-out $i;
}

img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    max-width: 100%;
}

.container {
    width: 100%;
    max-width: 1140px;
}

ul.nav.menu {
    padding: 0px;
    text-align: center;

    &>li {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        padding: 0px 19px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &>ul {
            display: none;
            // display: block $i;
            position: absolute;
            // background-color: #f9f9f9;
            min-width: 243px;
            // box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            padding: 6px 0px;
            z-index: 1;
            padding-top: 0px;
            text-align: right;

            li {
                display: block;
                position: relative;
                background: #e7e9e4;
                line-height: 30px;
                margin-bottom: 0px;

                &:not(:last-child) {
                    border-bottom: 1px solid #fff;
                }

                a {
                    color: #5c5c5c;
                    padding: 6px 20px;
                    font-size: 16px;
                    font-weight: 400;
                    display: block;
                }

                &:hover,
                &.active {
                    background: $greenishColor;

                    a {
                        color: #fff;
                    }
                }
            }
        }

        &:hover {
            &>ul {
                display: block;
            }
        }

        &:not(:last-child) {
            &:after {
                position: absolute;
                top: 6px;
                right: 0;
                content: '|';
                font-size: 24px;
            }
        }

        a,
        .nav-header {
            font-size: 17px;
            font-weight: 500;
            font-size: 18px;
            color: #383838;
            position: relative;
            line-height: 30px;
            padding: 8px 0px;
            cursor: pointer;
            text-transform: uppercase;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                left: 50%;
                right: 50%;
                bottom: 0;
                background: #ffdd00;
                height: 3px;
                -webkit-transition-property: left, right;
                transition-property: left, right;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
        }

        a:hover {
            &:before {
                left: 0;
                right: 0;
            }
        }

        &.active {

            &>a,
            &>ul>li.active>a {
                &:before {
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}

div[itemprop="articleBody"] {

    p,
    li {
        a {
            color: #fff;
            text-decoration: underline;
        }
    }
}

.fixed-top {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    margin: 0;
    background: #fff;
    height: 82px;
    padding: 13px 0px;
    -webkit-box-shadow: inset 0px -1px 0px 1px rgba(172, 172, 172, 1);
    -moz-box-shadow: inset 0px -1px 0px 1px rgba(172, 172, 172, 1);
    box-shadow: inset 0px -1px 0px 1px rgba(172, 172, 172, 1);
    display: flex;
    justify-content: space-around;
    align-items: center;

    @include respond(1600) {
        display: flex;
        align-items: center;
    }

    @include respond(1400) {
        justify-content: end;
    }

    .mobile-menu .con {
        margin-top: 0;
    }

    &.fixed {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 999999;

        // .mobile-menu .con {
        // 	margin-top: 4px;
        // }
    }
}

.fa {
    font-family: FontAwesome $i;
}

#offcanvas {
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
    z-index: -3;
    top: 0;
    right: 0%;
    background: transparent;

    .container {
        max-width: 100%;
        right: -100%;
        // background: rgba(0, 102, 165, 0.8);
        width: 100%;

        .search {
            input[name="searchword"] {
                border-radius: 50px 0px 0px 50px;
                padding-left: 10px;
                width: 73%;
                max-width: 1162px;
                border-right: 0;
                height: 55px;
                border: 2px solid #fff;
                background: $darkBlue;
                font-size: 26px;
                color: #000;
                float: left;

                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #fff;
                }

                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #fff;
                }

                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: #fff;
                }

                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: #fff;
                }
            }
        }

        .close-btn {
            text-align: right;

            img {
                background: rgba(255, 255, 255, 0.7);
                border-radius: 50%;
            }
        }

        ul li {
            width: 100%;
            text-align: right;
            background: #fff;
            margin-bottom: 3px;
            border-bottom: 0px;
            border: 1px solid $yellow;
            padding: 0 26px !important;
            // border-radius: 50px;

            &:after {
                display: none;
            }

            &.active {
                i.fa {
                    color: #fff $i;
                }
            }

            a {
                border-bottom: 0px;
                color: #383838;
                text-transform: uppercase;
                font-size: 26px;
                background-color: transparent;
                // border-radius: 50px;
                line-height: 40px;

                &:after {
                    display: none;
                }

                .fa-caret-down {
                    display: none $i;
                }
            }

            ul {
                width: 80%;
                background-color: #fff;
                min-width: 80%;
                position: relative;
                margin: 0 auto;

                li {
                    //background: @lightblue;
                    border: 0;

                    &:before {
                        display: none;
                    }

                    a {
                        font-size: 16px !important;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    &.opened {
        z-index: 99999;
        background: rgba(240, 225, 52, 0.8);
        opacity: 1;
        overflow: scroll;
        padding-bottom: 200px;
        padding-top: 20px;
        right: 0;
        margin-top: 72px;
        position: fixed;
    }

    &.fixed-opened {
        margin-top: 72px;
    }

    .nav.menu {
        li {
            position: relative;

            &.parent {
                .dropdown-menu {
                    position: relative;
                    background-color: transparent;
                    border: 0px;
                    box-shadow: none;
                    width: 100%;
                }
            }

            a {
                white-space: inherit;
                display: inline-block;
            }

            i {
                cursor: pointer;
                padding: 14px;
                position: absolute;
                left: 0;
                color: #fff;
                z-index: 0;
                top: 0;
            }
        }
    }
}

.ltr {
    #offcanvas {
        .nav.menu {
            li {
                a {
                    display: block;
                    text-align: left;
                }

                i {
                    left: auto;
                    right: 0;
                }

                &.parent {
                    ul {
                        li {
                            a {
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }
    }
}

#offcanvas .nav.menu li:not(.deeper) a {
    display: block;
}

a {
    color: $greenColor;

    &:hover {
        text-decoration: none;
        // color: #000 $i;
        // background-color: transparent $i;
    }
}

.nopadding {
    padding: 0;
}

.margin20 {
    margin-bottom: 20px;
}

.inline-block {
    display: inline-block;
    vertical-align: top;
}

.center-text {
    text-align: center;
}

.mobile-menu {
    text-align: right;
}

.preloader {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999999999;
    background: #fff;
    width: 100vw;
    height: 100vh;
    opacity: 1;

    .progress {
        height: 6px;
        margin-top: 0;
    }

    .preloader-wrapper.big {
        width: 100%;
        height: 100%;
        max-width: 250px;
        max-height: 250px;
        position: absolute;
        top: calc(50% - 125px);
        left: calc(50% - 125px);
    }
}

.loaded {
    .preloader {
        opacity: 0;
        z-index: -1;
    }
}

.mobile-menu {
    text-align: right;
    height: 100%;
    // background: #fff;
    max-width: 100px;
    margin-right: 0;
    margin-left: auto;

    .con {
        width: auto;
        margin: 0 auto;
        -webkit-transition: all .7s ease;
        -moz-transition: all .7s ease;
        -ms-transition: all .7s ease;
        -o-transition: all .7s ease;
        transition: all .7s ease;
        cursor: pointer;
        display: inline-block;
        margin-top: 9px;
        margin-right: 20px;

        .bar {
            display: block;
            height: 5px;
            width: 50px;
            background: #fff;
            margin: 10px auto;
            border-radius: 10px;
            -webkit-transition: all .7s ease;
            -moz-transition: all .7s ease;
            -ms-transition: all .7s ease;
            -o-transition: all .7s ease;
            transition: all .7s ease;
        }

        .middle {
            margin: 0 auto;
        }

        &.clicked {
            .top {
                -webkit-transform: translateY(15px) rotateZ(45deg);
                -moz-transform: translateY(15px) rotateZ(45deg);
                -ms-transform: translateY(15px) rotateZ(45deg);
                -o-transform: translateY(15px) rotateZ(45deg);
                transform: translateY(15px) rotateZ(45deg);
            }

            .bottom {
                -webkit-transform: translateY(-15px) rotateZ(-45deg);
                -moz-transform: translateY(-15px) rotateZ(-45deg);
                -ms-transform: translateY(-15px) rotateZ(-45deg);
                -o-transform: translateY(-15px) rotateZ(-45deg);
                transform: translateY(-15px) rotateZ(-45deg);
            }

            .middle {
                width: 0;
            }
        }
    }
}

//Form Sent
.fox-container {
    .alert.alert-success {
        color: #fff $i;
        background-color: $bordo $i;
        border-color: #fff $i;
        text-align: center;
        font-size: 34px;
        margin-top: 200px;

        .close {
            color: #fff $i;
            text-shadow: none $i;
            opacity: 1 $i;
            font-size: 30px $i;
        }

        .fox-messages {
            text-shadow: none;
            color: #fff;
        }
    }
}

/*===============================
=            General            =
===============================*/
.borderd {
    margin: 0;
    text-align: center;
    position: relative;
    background: url(#{$images}title_borderd_line.png) no-repeat;
    background-position: 0% 50%;
    background-size: 100%;

    &:before,
    &:after {
        content: '';
        width: 14px;
        height: 14px;
        background-color: #f0e134;
        position: absolute;
        right: 0;
        top: calc(100% / 2 - 7px);
    }

    &:before {
        right: auto;
        left: 0;
    }

    span {
        font-size: 28px;
        color: #fff;
        display: inline-block;
        padding: 10px 25px;
        background-color: #f0e134;
        font-weight: 400;
        text-transform: uppercase;
    }
}

body:not(.id-101) {
    .hp-sites {
        border-bottom: 1px solid #acacac;
    }
}

.hp-sites {
    text-align: center;
    margin-bottom: 72px;

    >.inline-block {
        width: calc(100%/5);
        border-top: 6px solid;
        margin-right: -4px;
        padding-top: 24px;
        position: relative;

        &:not(:last-child) {
            &:after {
                content: '';
                width: 1px;
                height: 278px;
                right: 0;
                top: calc(50% - 139px);
                background-color: #dadada;
                position: absolute;
            }
        }

        &:hover {
            figure {
                &:first-of-type {
                    img {
                        /* Disable grayscale on hover */
                        -webkit-filter: grayscale(0);
                        filter: none;
                    }
                }
            }
        }

        figure {
            margin-bottom: 40px;

            &:first-of-type {
                img {
                    filter: gray;
                    /* IE6-9 */
                    -webkit-filter: grayscale(1);
                    /* Google Chrome, Safari 6+ & Opera 15+ */
                    filter: grayscale(1);
                    /* Microsoft Edge and Firefox 35+ */
                    -webkit-transition: all ease-out 300ms;
                    -moz-transition: all ease-out 300ms;
                    -o-transition: all ease-out 300ms;
                    transition: all ease-out 300ms;
                    max-height: 99px;
                }
            }

            &:last-of-type {
                margin-bottom: 60px;
            }
        }

        h4 {
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 40px;
            color: #000;
        }

        p {
            &:not(.readmore) {
                max-width: 270px;
                margin: 0 auto;
                margin-bottom: 40px;
                color: #171812;
            }

            &.readmore {
                font-size: 25px;
                color: #fff;
                font-weight: 400;
                margin: 0;
                padding: 13px 0px;
            }
        }

        &.moulds,
        &.molds,
        &.תבניות {
            border-top-color: #f9c0aa;

            &:hover {
                box-shadow: 0 8px 17px 0 rgba(249, 192, 170, .7);
            }

            .readmore {
                background-color: #f9c0aa;
            }
        }

        &.electronic,
        &.אלקטרוניקה {
            border-top-color: #b6ebea;

            &:hover {
                box-shadow: 0 8px 17px 0 rgba(182, 235, 234, .7);
            }

            .readmore {
                background-color: #b6ebea;
            }
        }

        &.plastic,
        &.פלסטיק {
            border-top-color: #fce0af;

            &:hover {
                box-shadow: 0 8px 17px 0 rgba(252, 224, 175, .7);
            }

            .readmore {
                background-color: #fce0af;
            }
        }

        &.metal,
        &.מתכת {
            border-top-color: #ccd4a8;

            &:hover {
                box-shadow: 0 8px 17px 0 rgba(204, 212, 168, .7);
            }

            .readmore {
                background-color: #ccd4a8;
            }
        }

        &.wheelchairs,
        &.מוצרים-לבעלי-מוגבלויות,
        &.מוצרים-לאנשים-עם-מוגבלות {
            border-top-color: #c0d6d6;

            &:hover {
                box-shadow: 0 8px 17px 0 rgba(192, 214, 214, .7);
            }

            .readmore {
                background-color: #c0d6d6;
            }
        }

        &.palmex-israel,
        &.פלמקס-ישראל {
            border-top-color: #AFE2D3;

            &:hover {
                box-shadow: 0 8px 17px 0 rgba(175, 226, 211, .7);
            }

            .readmore {
                background-color: #AFE2D3;
            }
        }

        &.zriha-medical,
        &.זריחה-מדיקל {
            border-top-color: #F39C41;

            &:hover {
                box-shadow: 0 8px 17px 0 rgba(243, 157, 65, 0.7);
            }

            .readmore {
                background-color: #F39C41;
            }
        }
    }
}

.side-social {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9;

    a {
        display: inline-block;
        padding: 8px;
        font-size: 18px;
        color: #636363;
        background-color: #fedc31;
        width: 40px;
        text-align: center;
    }
}

/*=====  End of General  ======*/