//=======================================================================
// CUSTOM
//=======================================================================
// background: url(#{$images}footer_bg.jpg) no-repeat;
// bottom: 0;
// left: 50%;
// background-color: #e8e6cd;
// transform: translate(-50%, -50%);

/*----------  Header  ----------*/
#header {
    height: 82px;

    .inline-block {
        vertical-align: middle;
    }

    .logo {
        margin-left: 36px;
        margin-right: 66px;
    }

    .phone {
        margin-right: 30px;

        @include respond(1600) {
            margin: 0px 10px;
        }

        figcaption,
        img {
            display: inline-block;
            vertical-align: middle;
            font-size: 25px;
            color: #747474;
        }

        img {
            margin-right: 13px;
        }
    }

    .search {
        width: 316px;
        margin-left: 20px;
        margin-right: 35px;

        .search-form {
            form {
                .epc-search-box {
                    @include transition(all .3s linear);
                    width: 40px;

                    input {
                        border: 0;
                        outline: 0;
                        line-height: 35px;
                        height: 35px;
                        font-weight: 400;
                        margin-bottom: 0;

                        &::-webkit-input-placeholder {
                            /* Chrome/Opera/Safari */
                            color: #fff;
                            text-transform: uppercase;
                        }

                        &::-moz-placeholder {
                            /* Firefox 19+ */
                            color: #fff;
                            text-transform: uppercase;
                        }

                        &:-ms-input-placeholder {
                            /* IE 10+ */
                            color: #fff;
                            text-transform: uppercase;
                        }

                        &:-moz-placeholder {
                            /* Firefox 18- */
                            color: #fff;
                            text-transform: uppercase;
                        }
                    }

                    .btn {
                        position: absolute;
                        right: 0;
                        top: 0;
                        background-color: transparent;
                        outline: 0;
                        margin: 0;
                        padding: 0;
                        padding: 5px 7px;
                        box-shadow: none $i;
                    }

                    .epc-live-search {
                        position: absolute;
                        background: #fff;
                        padding: 10px;
                        width: 100%;
                    }

                    &.active {
                        width: 100%;

                        input {
                            border: 1px solid #D5D5D5;

                            &::-webkit-input-placeholder {
                                /* Chrome/Opera/Safari */
                                color: #c7c7c7;
                                text-transform: uppercase;
                            }

                            &::-moz-placeholder {
                                /* Firefox 19+ */
                                color: #c7c7c7;
                                text-transform: uppercase;
                            }

                            &:-ms-input-placeholder {
                                /* IE 10+ */
                                color: #c7c7c7;
                                text-transform: uppercase;
                            }

                            &:-moz-placeholder {
                                /* Firefox 18- */
                                color: #c7c7c7;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }

    .language-switcher {
        margin-right: 60px;

        .language-switcher-inner {
            .mod-languages {
                .lang-inline {
                    li {
                        margin: 0;
                        padding: 0;
                        display: inline-block;
                        vertical-align: top;

                        a {
                            margin: 0;
                            padding: 4px;
                            font-size: 13px;
                            color: #595959;
                            display: block;
                            width: 58px;
                            text-align: center;
                        }

                        &.lang-active {
                            float: left;

                            a {
                                color: #fff;
                                background-color: #595959;
                            }
                        }
                    }
                }
            }
        }
    }
}

#header,
#offcanvas {
    .search {
        width: fit-content;
        margin-left: 90px;
        margin-right: 35px;

        @include respond(1400) {
            position: fixed;
            right: 0;
        }
    }

    .search-form,
    .epc-module-search {
        .epc-search-box {
            background-color: #fff;

            >input {
                border: 1px solid #D5D5D5;
                outline: 0;
                line-height: 35px;
                height: 35px;
                font-weight: 400;
                margin-bottom: 0;

                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #c7c7c7;
                    text-transform: uppercase;
                }

                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #c7c7c7;
                    text-transform: uppercase;
                }

                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: #c7c7c7;
                    text-transform: uppercase;
                }

                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: #c7c7c7;
                    text-transform: uppercase;
                }
            }

            .btn {
                position: absolute;
                right: 0;
                top: 0;
                background-color: transparent;
                outline: 0;
                margin: 0;
                padding: 0;
                padding: 5px 7px;
                box-shadow: none $i;
            }

            .epc-live-search {
                position: absolute;
                background: #fff;
                padding: 10px;
                width: 100%;
            }
        }
    }
}

/*----------  End Of Header  ----------*/

//Main Homepage
.homepage {
    #main-component {
        .epc-page {
            .hp-top {
                height: 927px;
                background-size: cover;
                background-attachment: fixed;

                .parallax-container {
                    position: absolute;
                    height: 927px;
                    width: 100%;
                }

                >.container {
                    width: 100%;
                    max-width: 100%;
                    height: 100%;
                    padding: 0;
                    background: url(#{$images}hp_top_squares_bg.png) no-repeat;
                    background-size: cover;
                    background-position: 0% 0%;
                    z-index: 2;
                    position: relative;

                    .text-cont {
                        max-width: 1109px;
                        text-align: left;
                        margin: 0 auto;
                        margin-left: 108px;
                        margin-top: 375px;
                        background-color: rgba(255, 255, 255, .65);
                        padding-top: 53px;
                        padding-left: 83px;
                        padding-bottom: 53px;

                        h1 {
                            font-size: 44px;
                            font-weight: 400;
                            color: #131313;
                            margin: 0;
                            line-height: 49px;
                        }
                    }
                }
            }

            .hp-history {
                >.container {
                    max-width: 100%;
                    width: 100%;
                    padding: 0;

                    .first-row {
                        margin-bottom: 6px;

                        .inline-block {
                            width: 100%;
                            margin-right: -4px;
                            position: relative;

                            &:nth-child(1) {
                                max-width: 27.28%;

                                &:after {
                                    position: absolute;
                                    content: '';
                                    background: url(#{$images}hp_history_firstrow_leftimage_squares.png) no-repeat;
                                    width: 141px;
                                    height: 142px;
                                    right: 10px;
                                    bottom: 10px;
                                }
                            }

                            &:nth-child(2) {
                                max-width: 25.02%;
                                text-align: center;
                            }

                            &:nth-child(3) {
                                max-width: 47.7%;

                                &:after {
                                    position: absolute;
                                    content: '';
                                    background: url(#{$images}hp_history_firstrow_rightimage_squares.png) no-repeat;
                                    width: 338px;
                                    height: 338px;
                                    left: 10px;
                                    top: 10px;
                                }
                            }
                        }
                    }

                    .last-row {
                        .inline-block {
                            width: 100%;
                            margin-right: -4px;
                            position: relative;

                            &:nth-child(1) {
                                max-width: 52.35%;

                                &:before {
                                    position: absolute;
                                    content: '';
                                    background: url(#{$images}hp_history_lastrow_leftimage_numbertwo_squares.png) no-repeat;
                                    width: 80px;
                                    height: 80px;
                                    top: 37px;
                                    right: 47px;
                                }

                                &:after {
                                    position: absolute;
                                    content: '';
                                    background: url(#{$images}hp_history_lastrow_leftimage_squares.png) no-repeat;
                                    width: 274px;
                                    height: 274px;
                                    left: 10px;
                                    bottom: 10px;
                                }
                            }

                            &:nth-child(2) {
                                max-width: 24.751%;
                                text-align: center;
                            }

                            &:nth-child(3) {
                                max-width: 22.899%;

                                &:after {
                                    position: absolute;
                                    content: '';
                                    background: url(#{$images}hp_history_lastrow_rightimage_squares.png) no-repeat;
                                    width: 107px;
                                    height: 107px;
                                    left: 10px;
                                    bottom: 10px;
                                }
                            }
                        }
                    }

                    .first-row,
                    .last-row {
                        .inline-block {
                            &:nth-child(2) {
                                .inner-cont {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 100%;
                                    max-width: 378px;

                                    h4 {
                                        font-size: 81px;
                                        font-weight: 400;
                                        color: #484848;
                                        margin-top: 0;
                                        padding-bottom: 17px;
                                        margin-bottom: 40px;
                                        border-bottom: 3px solid $yellow;
                                        display: inline-block;
                                    }

                                    p {
                                        font-size: 20px;
                                        font-weight: 400;
                                        color: #202020;
                                        line-height: 24px;
                                        text-align: justify;
                                        margin: 0;

                                        &:last-child {
                                            text-align: center;
                                        }
                                    }

                                    a {
                                        margin-top: 51px;
                                        color: #484848;
                                        font-weight: 400;
                                        font-size: 28px;
                                        display: inline-block;
                                        padding-bottom: 8px;
                                        border-bottom: 1px solid #484848;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .hp-counter {
                >.container {
                    max-width: 1352px;
                    text-align: center;

                    h3 {
                        margin: 50px 0px;
                        text-transform: uppercase;
                        font-size: 35px;
                        font-weight: 400;
                        color: #797a7d;
                    }

                    .counters-wrapper {
                        width: 100%;

                        .inline-block {
                            width: 33%;
                            height: 223px;
                            padding: 30px 0px 40px 0px;
                            border-left: 2px solid #808183;
                            border-top: 0;
                            border-bottom: 0;

                            &:last-child {
                                border-right: 2px solid #808183;
                            }

                            h4 {
                                text-transform: uppercase;
                                font-size: 35px;
                                font-weight: 400;
                                color: #808183;
                                line-height: 35px;
                                margin-top: 0;
                                margin-bottom: 0;
                            }

                            .counter {
                                text-transform: uppercase;
                                font-size: 60px;
                                font-weight: 400;
                                color: #797a7d;
                                line-height: 60px;
                                margin: 20px 0px;
                            }
                        }
                    }

                    a {
                        display: block;
                        font-size: 35px;
                        font-weight: 400;
                        color: #3f3f3f;
                        text-align: center;
                        margin-top: 54px;
                        border: 2px solid #000;
                        padding: 15px 0px;
                        margin-bottom: 70px;
                    }
                }
            }
        }
    }
}


/*=============================================
=            Verticals Page            =
=============================================*/

.view-item {

    .service-page,
    .vertical-page,
    .project-page {

        .service-content,
        .vertical-content,
        .project-content {
            max-width: 1391px;
            margin: 0 auto;

            >h1 {
                margin-top: 60px;
                margin-bottom: 40px;
            }

            >.row {
                // margin: 0;

                .col-sm-6 {
                    line-height: 22px;

                    @include respond(768) {
                        text-align: inherit;
                    }

                    p {
                        &:first-of-type {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .related-verticals,
        .related-services {
            background-color: $yellow;
            padding-top: 36px;
            padding-bottom: 47px;

            .inner-cont {
                max-width: 1391px;
                margin: 0 auto;

                .borderd {
                    background-image: url(#{$images}title_borderd_line_white.png);
                    background-repeat: no-repeat;
                    margin-bottom: 50px;

                    &:before,
                    &:after {
                        background-color: #fff;
                    }

                    span {
                        color: $yellow;
                        background-color: #fff;
                    }
                }

                .related-verticals-wrapper,
                .related-services-wrapper {
                    ul {
                        text-align: center;

                        li {
                            display: inline-block;
                            vertical-align: top;
                            width: 49%;
                            margin-bottom: 5px;
                            border: 1px solid #808183;
                            padding: 18px;
                            background-color: #fff;

                            a {
                                color: #7a7979;
                                text-transform: uppercase;
                                font-size: 24px;
                                display: block;
                                height: 100%;
                            }
                        }
                    }
                }

                .related-services-wrapper {
                    ul {
                        li {
                            width: 33%;
                            max-width: 421px;

                            a {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }

        .service-gallery {
            max-width: 1391px;
            margin: 0 auto;
            padding-top: 30px;
            padding-bottom: 40px;

            .service-gallery-container {
                margin-top: 30px;
                position: relative;
                line-height: 0;
                -webkit-column-count: 4;
                -webkit-column-gap: 10px;
                -moz-column-count: 4;
                -moz-column-gap: 10px;
                column-count: 4;
                column-gap: 10px;

                .service-gallery-image {
                    width: 100%;
                    height: auto;
                    margin-bottom: 10px;

                    a {
                        position: relative;
                        display: block;
                        height: 100%;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: auto;
                            transition: all .3s linear;
                        }

                        .overlay {
                            position: absolute;
                            width: 100%;
                            height: 60px;
                            background-color: rgba(0, 0, 0, 0.4);
                            display: block;
                            bottom: 0;
                            opacity: 0;
                            -webkit-transition: all 300ms ease-in-out $i;
                            -moz-transition: all 300ms ease-in-out $i;
                            -ms-transition: all 300ms ease-in-out $i;
                            -o-transition: all 300ms ease-in-out $i;
                            transition: all 300ms ease-in-out $i;

                            .content {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                color: #fff;
                                font-size: 16px;
                                line-height: 22px;
                                padding: 4px 8px;
                                width: 100%;
                                text-align: center;
                                word-break: break-all;
                            }
                        }

                        &:hover {
                            .overlay {
                                opacity: 1;
                            }

                            img {
                                transform: scale(1.3);
                            }
                        }
                    }
                }
            }

            .let-us-help-you {
                margin-top: 40px;
                width: 100%;
                display: block;
                text-align: center;
                font-size: 36px;
                font-weight: 400;
                border: 2px solid $yellow;
                color: $yellow;
                padding: 13px;
            }
        }

        .vertical-projects {
            max-width: 1391px;
            margin: 0 auto;
            padding-top: 30px;
            padding-bottom: 40px;
            text-align: center;

            >.inner-cont {
                >.borderd {
                    margin-bottom: 30px;
                }
            }

            .single-project {
                width: 33%;
                margin-bottom: 5px;
                background-color: #fff;
                text-align: center;

                a {
                    figure {
                        .cont {
                            height: 305px;
                            position: relative;
                            border: 1px solid #cecece;
                            margin-bottom: 8px;

                            img {
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }

                        figcaption {
                            font-size: 25px;
                            font-weight: 400;
                            color: #fff;
                            background-color: $yellow;
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }

    .service-page.item_with_childs {
        .service-content {
            .borderd {
                +.row {
                    margin-bottom: 60px;
                }
            }

            .sons-items-wrapper {
                >hr {
                    border-color: #ccc9c9;
                }

                >.row {
                    &:first-of-type {
                        .col-sm-6 {
                            dl {
                                dt {
                                    border-top: 1px solid $yellow;
                                }
                            }
                        }
                    }

                    &:nth-of-type(even) {
                        .col-sm-6 {
                            &.text-justify {
                                float: left;
                            }
                        }
                    }

                    .col-sm-6 {
                        figure {
                            display: block;
                            height: 100%;
                            width: 100%;
                            background-size: cover;
                            background-position: center;
                        }

                        dl {
                            margin: 0;
                            color: #595959;

                            dt {
                                text-align: center;
                                border-bottom: 1px solid $yellow;
                                font-weight: 400;
                                font-size: 2em;
                                padding: 5px 0px;
                            }

                            dd {
                                margin: 0;
                                font-size: 1em;
                            }
                        }

                        .read-more {
                            text-align: center;
                            display: block;
                            width: 100%;
                            font-size: 1.25em;
                            color: #595959;
                            border: 1px solid $yellow;
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }

    &.ltr {
        .service-page.item_with_childs {
            .service-content {
                .sons-items-wrapper {
                    >.row {
                        &:nth-of-type(even) {
                            .col-sm-6.text-justify {
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

.view-category {
    .service-page.item_with_childs {
        .service-content {
            .borderd {
                margin-top: 40px;

                +.row {
                    margin-bottom: 60px;
                }
            }

            .sons-items-wrapper {
                >hr {
                    border-color: #ccc9c9;
                }

                >.row {
                    &:first-of-type {
                        .col-sm-6 {
                            dl {
                                dt {
                                    border-top: 1px solid $yellow;
                                }
                            }
                        }
                    }

                    &:nth-of-type(even) {
                        .col-sm-6 {
                            &.text-justify {
                                float: left;
                            }
                        }
                    }

                    .col-sm-6 {
                        figure {
                            display: block;
                            height: 100%;
                            width: 100%;
                            background-size: cover;
                            background-position: center;
                        }

                        dl {
                            margin: 0;
                            color: #595959;

                            dt {
                                text-align: center;
                                border-bottom: 1px solid $yellow;
                                font-weight: 400;
                                font-size: 2em;
                                padding: 5px 0px;
                            }

                            dd {
                                margin: 0;
                                font-size: 1em;
                            }
                        }

                        .read-more {
                            text-align: center;
                            display: block;
                            width: 100%;
                            font-size: 1.25em;
                            color: #595959;
                            border: 1px solid $yellow;
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }

    &.ltr {
        .service-page.item_with_childs {
            .service-content {
                .sons-items-wrapper {
                    >.row {
                        &:nth-of-type(2n) {
                            .col-sm-6.text-justify {
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====  End of Verticals Page  ======*/



/*======================================
=            The Group Page            =
======================================*/

body.the-group {
    #main-component {
        .epc-page {
            margin-bottom: 80px;

            .top {
                position: relative;

                .parallax-container {
                    height: 425px;

                    .parallax {
                        img {
                            bottom: -80px;
                        }
                    }

                    +.container {
                        width: 100%;
                        max-width: 100%;
                        height: 100%;
                        padding: 0;
                        background: url(#{$images}hp_top_squares_bg.png) no-repeat;
                        background-size: cover;
                        background-position: 0% 4%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 2;
                    }
                }
            }

            .content {
                >.container {
                    max-width: 1420px;

                    .about-section {
                        >.borderd {
                            margin-top: 30px;
                            margin-bottom: 48px;

                            +p {
                                margin-top: 0;
                            }

                            ~* {
                                max-width: 1226px;
                                margin: 0 auto;
                            }
                        }
                    }

                    .management-section {
                        margin-top: 30px;

                        >.borderd {
                            margin-bottom: 68px;
                        }

                        .peoples-wrapper {
                            max-width: 1226px;
                            margin: 0 auto;
                            text-align: center;

                            .row {
                                >.inline-block {
                                    width: calc(100%/5);
                                    margin-right: -4px;
                                    text-align: center;
                                    padding: 0px 17px;
                                    margin-bottom: 30px;

                                    @include respond(768) {
                                        width: 100%;
                                    }

                                    figure {
                                        img {
                                            max-width: 221px;
                                            border: 1px solid #595959;
                                        }
                                    }

                                    h4 {
                                        font-size: 25px;
                                        font-weight: 400;
                                        text-align: center;
                                        color: #828282;
                                    }

                                    p {
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: #515254;
                                        text-align: justify;
                                    }

                                    a {
                                        display: block;
                                        text-align: center;
                                        font-size: 16px;
                                        color: #515254;
                                        font-weight: 400;
                                        text-decoration: underline;
                                        padding: 10px;
                                        border: 1px solid #595959;
                                        word-wrap: break-word;
                                    }
                                }

                                &:not(:first-child) {
                                    >.inline-block {
                                        width: calc(100%/6);

                                        @include respond(768) {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====  End of The Group Page  ======*/

/*====================================
=            History Page            =
====================================*/

body.history-page {
    #main-component {
        .epc-page {
            .top {
                position: relative;

                .parallax-container {
                    height: 1108px;

                    +.container {
                        width: 100%;
                        max-width: 100%;
                        height: 100%;
                        padding: 0;
                        background: url(#{$images}history_squares_bg.png) no-repeat;
                        background-size: cover;
                        background-position: 0% 4%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 2;

                        .top-titles,
                        .collapsible-wrapper {
                            max-width: 1420px;
                            margin: 0 auto;
                            float: none;

                            @include respond(768) {
                                padding-right: 15px $i;
                            }
                        }

                        .top-titles {
                            >div {
                                display: inline-block;
                                text-align: right;
                                margin-bottom: 33px;

                                @include respond(768) {
                                    padding: 0px 15px;

                                    h1,
                                    h2 {
                                        font-size: 20px;
                                    }
                                }
                            }

                            h1 {
                                margin-top: 84px;
                                font-size: 40px;
                                font-weight: 400;
                                color: #515254;
                                margin-bottom: 14px;
                            }

                            h2 {
                                margin: 0;
                                font-size: 25px;
                                font-weight: 400;
                                color: #515254;
                            }
                        }

                        .collapsible-wrapper {

                            /* Solutions */
                            .slick-list,
                            .slick-track,
                            .slick-slide li {
                                height: 100%;
                            }

                            // .slick-track {
                            .single-history {
                                height: auto;
                                margin: 0;
                                padding: 0;
                                border: 0;
                                box-shadow: none;
                                outline: 0;
                                display: block;
                                margin-bottom: 4px;

                                &.slick-current.slick-center {
                                    height: 100% !important;
                                    max-height: 368px !important;
                                }

                                li {
                                    .collapsible-header {
                                        margin: 0;
                                        display: block;
                                        max-width: 170px;
                                        outline: 0;
                                        text-align: center;
                                        font-size: 48px;
                                        font-weight: 400;
                                        color: #515254;
                                        margin-right: 0;
                                        margin-left: auto;
                                        height: 90px;
                                        position: relative;
                                        margin-right: 93px;
                                        float: none;

                                        &:before {
                                            content: '';
                                            background: url(#{$images}history_page_plusminus.png) no-repeat;
                                            display: block;
                                            position: absolute;
                                            right: -93px;
                                            top: 0;
                                            width: 88px;
                                            height: 90px;
                                            background-position: 0% 0%;
                                        }
                                    }

                                    .collapsible-body {
                                        margin-top: 4px;
                                        padding: 10px;
                                        z-index: 9;
                                        position: relative;
                                        border: 0px;
                                        background-color: rgba(255, 255, 255, 0.80);
                                        float: none;

                                        figure {

                                            img,
                                            figcaption {
                                                display: inline-block;
                                                vertical-align: top;
                                            }

                                            figcaption {
                                                font-size: 20px;
                                                font-weight: 400;
                                                color: #515254;
                                                padding: 40px 60px 44px 60px;
                                                text-align: justify;
                                                max-width: calc(100% - 380px);

                                                @include respond(768) {
                                                    font-size: 17px;
                                                    text-align: initial;
                                                }

                                                strong {
                                                    font-size: 28px;

                                                    @include respond(768) {
                                                        font-size: 20px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &.active {
                                        .collapsible-header {
                                            &:before {
                                                background-position: 0% 100%;
                                            }
                                        }
                                    }
                                }
                            }

                            // } #Slick Track closing tag
                        }
                    }
                }
            }

            .bottom {
                max-width: 1200px;
                margin: 0 auto;
            }
        }
    }

    &.ltr {
        .mCSB_inside {
            >.mCSB_container {
                margin-right: 0;
                margin-left: 30px;

                .single-history {
                    li {
                        .collapsible-header {
                            margin-right: 0 $i;
                            margin-left: 93px $i;

                            &:before {
                                right: auto $i;
                                left: -93px $i;
                            }
                        }
                    }
                }
            }

            .mCSB_scrollTools {
                left: 0;
                right: auto;
            }
        }
    }
}

/*=====  End of History Page  ======*/

/*=======================================
=            Contact Us Page            =
=======================================*/

body.contact-us {
    #main-component {
        >.container {
            max-width: 100%;
            padding: 0;

            >.epc-page {
                .borderd {
                    max-width: 1418px;
                    margin: 0 auto;
                    margin-top: 40px;
                    margin-bottom: 26px;
                }

                >.contact-row {
                    margin-bottom: 0;
                    background-color: #f5f5f5;
                    padding: 50px 20px;

                    >.row {
                        max-width: 1418px;
                        margin: 0 auto;

                        .contact-form {
                            .fox-container {
                                .fox-column {
                                    &.fox-column6 {
                                        width: 49%;
                                        margin: 0;
                                        margin-right: 6px;
                                    }

                                    &.fox-column12 {
                                        min-height: auto;
                                    }

                                    .fox-item {
                                        float: none;
                                        width: 100%;
                                        margin-bottom: 6px;

                                        &.fox-item-text-area {
                                            margin-bottom: 0;
                                        }

                                        &.fox-item-captcha {
                                            margin-bottom: 12px;

                                            .fox-item-captcha-cnt {
                                                max-width: 100%;

                                                >img {
                                                    display: inline-block;
                                                    vertical-align: top;
                                                    border: 1px solid #c8c8c8;

                                                    +div {
                                                        display: inline-block;
                                                        vertical-align: top;
                                                        width: calc(100% - 156px);
                                                        margin-left: 6px;
                                                    }
                                                }
                                            }
                                        }

                                        &.fox-item-attachments {

                                            label,
                                            span {
                                                font-size: 16px;
                                                font-weight: 400;
                                            }

                                            >.controls {
                                                text-align: center;

                                                >div,
                                                >span {
                                                    display: inline-block;
                                                    vertical-align: top;
                                                    width: 33%;

                                                    @include respond(768) {
                                                        width: 100%;
                                                        max-width: 100% $i;
                                                        display: block;
                                                    }
                                                }

                                                >div {
                                                    &:first-of-type {
                                                        max-width: 20%;
                                                    }

                                                    .qq-upload-button-caption {
                                                        line-height: 46px;
                                                        padding: 0px 10px;
                                                    }
                                                }

                                                >span {
                                                    max-width: 30%;
                                                }

                                                .btn {
                                                    margin-top: 0;
                                                }
                                            }
                                        }

                                        .controls {
                                            float: none;
                                            width: 100%;

                                            input,
                                            textarea,
                                            .btn {
                                                width: 100%;
                                                height: 48px;
                                                background-color: #fff;
                                                border: 1px solid #c8c8c8;
                                                color: #858585;
                                                padding: 0px 25px;

                                                &::-webkit-input-placeholder {
                                                    /* Chrome/Opera/Safari */
                                                    color: #858585;
                                                }

                                                &::-moz-placeholder {
                                                    /* Firefox 19+ */
                                                    color: #858585;
                                                }

                                                &:-ms-input-placeholder {
                                                    /* IE 10+ */
                                                    color: #858585;
                                                }

                                                &:-moz-placeholder {
                                                    /* Firefox 18- */
                                                    color: #858585;
                                                }
                                            }

                                            textarea {
                                                padding-top: 15px;
                                                height: 156px !important;
                                            }

                                            .btn {
                                                margin: 0;
                                                padding: 0;
                                                outline: 0;
                                                box-shadow: none;
                                                border-radius: 0px;
                                                background-color: $yellow;
                                                margin-top: -6px;

                                                span {
                                                    color: #383838;
                                                    font-size: 21px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .sf-form {
                                .row {
                                    .col-sm-6 {

                                        input,
                                        textarea,
                                        .btn,
                                        select {
                                            width: 100%;
                                            height: 48px;
                                            background-color: #fff;
                                            border: 1px solid #c8c8c8;
                                            color: #858585;
                                            padding: 0 10px;

                                            &::-webkit-input-placeholder {
                                                /* Chrome/Opera/Safari */
                                                color: #858585;
                                            }

                                            &::-moz-placeholder {
                                                /* Firefox 19+ */
                                                color: #858585;
                                            }

                                            &:-ms-input-placeholder {
                                                /* IE 10+ */
                                                color: #858585;
                                            }

                                            &:-moz-placeholder {
                                                /* Firefox 18- */
                                                color: #858585;
                                            }
                                        }

                                        textarea {
                                            padding-top: 15px;
                                            height: 225px !important;
                                            margin-bottom: 7px;
                                        }

                                        .btn {
                                            margin: 0;
                                            padding: 0;
                                            outline: 0;
                                            box-shadow: none;
                                            border-radius: 0px;
                                            background-color: $yellow;
                                            margin-top: -6px;
                                            color: #383838;
                                            font-size: 21px;
                                        }
                                    }
                                }
                            }
                        }

                        .sites-contact-info {
                            >span:not(:last-of-type) {
                                margin-bottom: 4px;
                            }

                            >p strong {
                                margin: 22px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .sites-contact-info {
        text-align: left;

        >span {
            display: block;
            position: relative;
            font-size: 16px;
            color: #383838;
            max-width: 225px;
            margin: 0 auto;

            &:not(:last-of-type) {
                margin-bottom: 12px;
            }

            a {
                color: #383838;
                text-decoration: underline;
            }

            &:before {
                content: '';
                width: 20px;
                height: 24px;
                position: relative;
                display: inline-block;
                vertical-align: text-bottom;
                margin-right: 6px;
            }

            &.phone {
                &:before {
                    background: url(#{$images}sites_contact_phone.png) no-repeat;
                }
            }

            &.fax {
                &:before {
                    background: url(#{$images}sites_contact_fax.png) no-repeat;
                }
            }

            &.email {
                &:before {
                    background: url(#{$images}sites_contact_email.png) no-repeat;
                }
            }
        }

        >p {
            font-size: 16px;
            color: #383838 !important;
            max-width: 225px !important;
            margin: 0 auto !important;

            strong {
                margin: 30px 0px;
                display: block;
            }
        }

        >a {
            font-size: 21px;
            font-weight: 400;
            color: #383838;
            display: block;
            text-align: center;
            padding: 7px 0px;
            border: 1px solid #c8c8c8;
            max-width: 300px;
            margin: 30px auto;
            background-color: $yellow;
        }
    }
}

/*=====  End of Contact Us Page  ======*/

/*====================================
=            HP Verticals            =
====================================*/

.hp-verticals {
    margin-bottom: 50px;

    >.container {
        max-width: 100%;
        padding: 0;

        >.row {
            margin-bottom: 0;
            display: inline-block;
            width: 50%;
            margin: 0;
            margin-right: -4px;
            vertical-align: top;

            @include respond(768) {
                display: table;
            }

            .col-sm-6 {
                padding: 0;
                position: relative;
                max-height: 207px;

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }

            .col-sm-6 {
                &.namer {
                    background-color: #FEDC31;

                    a {
                        display: block;
                        height: 100%;
                        text-align: center;
                        position: relative;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 38px;
                            letter-spacing: 10px;
                            font-weight: 400;
                            color: #fff;
                            padding: 20px 0;
                            border-top: 1px solid #fff;
                            border-bottom: 1px solid #fff;
                            text-transform: uppercase;
                            width: 330px;
                            max-width: 100%;
                        }
                    }
                }

                &.imager {
                    @include respond(768) {
                        display: table-header-group $i;

                        &:before {
                            display: none $i;
                        }
                    }

                    figure {
                        height: 100%;

                        img {
                            opacity: .7;
                            transition: all .5s;
                            height: 100%;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            &:nth-child(1),
            &:nth-child(5) {
                .imager {
                    &:before {
                        content: '';
                        position: absolute;
                        left: 10px;
                        top: 10px;
                        background: url(#{$images}hp_history_lastrow_leftimage_squares.png) no-repeat;
                        width: 120px;
                        height: 117px;
                        background-size: cover;
                        z-index: 2;
                        transform: rotate(270deg);
                    }
                }
            }

            &:nth-child(2),
            &:nth-child(6) {
                .imager {
                    float: right;

                    &:before {
                        content: '';
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                        background: url(#{$images}hp_history_firstrow_leftimage_squares.png) no-repeat;
                        width: 101px;
                        height: 102px;
                        background-size: cover;
                        z-index: 2;
                    }
                }
            }

            &:nth-child(3) {
                .namer {
                    float: right;
                }

                .imager {
                    &:before {
                        content: '';
                        position: absolute;
                        right: 25px;
                        top: 10px;
                        background: url(#{$images}hp_history_firstrow_leftimage_squares.png) no-repeat;
                        width: 101px;
                        height: 102px;
                        background-size: cover;
                        z-index: 2;
                        transform: rotate(270deg);
                    }
                }
            }

            &:nth-child(4) {
                .imager {
                    &:before {
                        content: '';
                        position: absolute;
                        left: 25px;
                        bottom: 10px;
                        background: url(#{$images}hp_history_lastrow_leftimage_squares.png) no-repeat;
                        width: 120px;
                        height: 117px;
                        background-size: cover;
                        z-index: 2;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

/*=====  End of HP Verticals  ======*/


/*==============================
=            Footer            =
==============================*/

#footer {
    >.container {
        max-width: 100%;
        padding: 0;

        .first-row {
            max-width: 1270px;
            margin: 0 auto;

            .inline-block {
                width: calc(100% / 6);
                margin-right: -4px;

                h5 {
                    font-weight: 400;
                    color: #878789;
                    font-size: 15px;
                    text-transform: uppercase;
                    padding: 15px;
                    margin-bottom: 30px;
                    border-left: 3px solid;
                }

                &.zriha {
                    h5 {
                        border-color: $yellow !important;
                    }
                }

                &.metalitec {
                    h5 {
                        border-color: #ccd4a8 !important;
                    }
                }

                &.sunrise {
                    h5 {
                        border-color: #f9c0aa !important;
                    }
                }

                &.maalot {
                    h5 {
                        border-color: #fce0af !important;
                    }
                }

                &.electo {
                    h5 {
                        border-color: #b6ebea !important;
                    }
                }

                &.seatara {
                    h5 {
                        border-color: #c0d6d6 !important;
                    }
                }

                ul.nav.menu {
                    text-align: left;
                    padding: 0px 15px 0px 15px;

                    li {
                        padding: 0;
                        display: block;
                        margin: 0;
                        margin-bottom: 8px;

                        &:after {
                            display: none;
                        }

                        a {
                            color: #858688;
                            font-size: 13px;
                            font-weight: 400;
                            padding: 0;
                            display: block;
                            margin: 0;
                            text-transform: capitalize;

                            &:before {
                                display: none;
                            }
                        }

                        ul {
                            display: none;
                        }
                    }
                }
            }
        }

        .last-row {
            margin: 0;
            margin-top: 55px;
            padding-bottom: 48px;
            padding-left: 45px;
            padding-right: 44px;
            font-size: 16px;
            color: #0c0c0c;

            .col-sm-8 {
                padding-left: 60px;
            }

            .theguy {
                text-align: right;
            }
        }
    }
}

/*=====  End of Footer  ======*/

//Search Page
.view-search {
    .search {
        h1 {
            font-size: 50px;
        }

        * {
            color: #000;
        }

        #searchForm {
            display: none;
        }

        .search-results {
            .result-title {
                font-size: 27px;
                text-decoration: underline;
            }

            .result-category {
                display: none;
            }
        }
    }
}

//Side Websites
.side-icons {
    position: fixed;
    top: 214px;
    z-index: 99;
    right: 0;

    ul {
        direction: rtl;
        padding: 0;

        li {
            &:not(:last-child) {
                margin-bottom: 2px;
            }

            a {
                margin-right: -192px;
                display: block;

                &:hover {
                    margin-right: 0;
                }

                span {
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;

                    &:not(.image-span) {
                        width: 190px;
                        font-size: 16px;
                        text-align: center;
                        color: #171812;
                        margin-left: -3px;
                    }

                    &.image-span {
                        max-width: 71px;
                    }
                }
            }
        }
    }
}

//Side ISO
.side-iso {
    position: absolute;
    top: 117px;
    left: 35px;
    z-index: 99;

    @include respond(768) {
        top: 67px;
        left: 5px;
        max-width: 40px;
    }
}

//Side Let Us Help You
.side-let-us-help-you {
    position: fixed;
    top: 100px;
    right: 35px;
    z-index: 99;

    a {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #FEDC31;
    }

    @include respond(768) {
        top: auto;
        right: auto;
        left: 4px;
        bottom: 4px;
        max-width: 40px;
    }
}