/*===================================            Responsive            ===================================*/

@media (max-width: 1900px) {
    #header {
        height: 72px;

        .fixed-top {
            height: 72px;
        }

        .logo {
            // margin-right: 46px;
            margin: 0px 10px;

            img {
                max-width: 180px;
            }
        }

        .search {
            // margin-left: 50px;
            margin: 0px 10px;
        }

        .language-switcher {
            // margin-right: 40px;
            margin: 0px;
        }

        ul.nav.menu {
            >li {
                padding: 0 14px;

                a,
                .nav-header {
                    font-size: 17px;
                }
            }
        }
    }

    .homepage {
        #main-component {
            .epc-page {
                .hp-history {
                    >.container {

                        .first-row,
                        .last-row {
                            .inline-block {
                                &:nth-child(2) {
                                    .inner-cont {
                                        h4 {
                                            font-size: 61px;
                                            margin-bottom: 20px;
                                        }

                                        p {
                                            font-size: 18px;
                                        }

                                        a {
                                            font-size: 26px;
                                            margin-top: 31px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .hp-verticals {
                    >.container {
                        >.row {
                            .col-sm-6.namer {
                                a {
                                    span {
                                        font-size: 68px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    body.history-page {
        #main-component {
            .epc-page {
                .top {
                    .parallax-container+.container {
                        .top-titles {
                            max-width: 1420px;
                            margin: 0 auto;
                            padding-left: 110px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1600px) {
    .homepage {
        #main-component {
            .epc-page {
                .hp-top {
                    height: 827px;

                    .parallax-container {
                        height: 827px;
                    }

                    .container {
                        .text-cont {
                            max-width: 959px;
                        }
                    }
                }

                .hp-history {
                    >.container {

                        .first-row,
                        .last-row {
                            .inline-block {
                                &:nth-child(2) {
                                    .inner-cont {
                                        padding: 10px 20px;

                                        h4 {
                                            font-size: 51px;
                                            margin-bottom: 20px;
                                        }

                                        p {
                                            font-size: 16px;
                                        }

                                        a {
                                            font-size: 22px;
                                            margin-top: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .hp-counter {
                    >.container {
                        a {
                            font-size: 31px;
                            padding: 10px 0;
                        }
                    }
                }
            }
        }
    }

    #footer {
        >.container {
            .last-row {
                padding-bottom: 28px;
            }
        }
    }

    #offcanvas {
        .epc-module-search {
            .epc-search-box {
                margin-bottom: 20px;
            }
        }

        .nav.menu {
            li {
                a {
                    text-align: center $i;
                    font-size: 22px;
                }
            }
        }
    }

    body.history-page {
        #main-component {
            .epc-page {
                .top {
                    .parallax-container {
                        height: 978px;

                        +.container {
                            .top-titles {
                                padding-left: 120px;
                            }

                            .top-titles,
                            .collapsible-wrapper {
                                max-width: 1220px;
                            }

                            .collapsible-wrapper {
                                .single-history {
                                    li {
                                        .collapsible-body {
                                            figure {
                                                figcaption {
                                                    padding: 20px 20px 20px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .rtl {
        #header {

            .logo,
            .phone,
            .language-switcher {
                float: left;
            }

            .language-switcher {
                margin-top: 10px;
            }
        }
    }
}

@media (max-width: 1400px) {
    .hp-sites {
        >.inline-block {
            figure {
                &:first-of-type {
                    img {
                        max-width: 200px;
                    }
                }
            }
        }
    }

    .homepage {
        #main-component {
            .epc-page {
                .hp-history {
                    >.container {

                        .first-row,
                        .last-row {
                            .inline-block {
                                &:nth-child(2) {
                                    .inner-cont {
                                        padding: 10px 20px;

                                        h4 {
                                            font-size: 41px;
                                            margin-bottom: 10px;
                                        }

                                        p {
                                            font-size: 16px;
                                            line-height: 18px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .hp-counter {
                    >.container {
                        a {
                            font-size: 31px;
                            padding: 10px 0;
                        }
                    }
                }

                .hp-verticals {
                    >.container {
                        >.row {
                            .col-sm-6.namer {
                                a {
                                    span {
                                        font-size: 48px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    body.the-group {
        #main-component {
            .epc-page {
                .content {
                    >.container {
                        .management-section {
                            .peoples-wrapper {
                                >.inline-block {
                                    a {
                                        padding: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .side-icons {
        width: fit-content;
    }

    .side-iso {
        width: 70px;
    }

    body.history-page {
        #main-component {
            .epc-page {
                .top {
                    .parallax-container {
                        height: 828px;

                        +.container {
                            .top-titles {
                                h1 {
                                    margin-top: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    body {
        >.hp-verticals {
            >.container {
                >.row {
                    .col-sm-6.namer {
                        a {
                            span {
                                font-size: 28px;
                            }
                        }
                    }
                }
            }
        }
    }

    .rtl {
        &.history-page {
            #main-component {
                .epc-page {
                    .top {
                        .parallax-container {
                            +.container {

                                .collapsible-wrapper,
                                .top-titles {
                                    max-width: 1020px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    #header {
        .inline-block {
            &.search {
                display: none;
            }

            &.menu {
                height: 70px;
                padding-left: 20px;
                position: absolute;
                right: 0;
                top: 0;
                padding-top: 10px;
                background-color: $yellow;

                #menu {
                    .hidden-sm.hidden-xs {
                        display: none;
                    }

                    .visible-sm.visible-xs {
                        display: block $i;
                    }
                }
            }

            &.language-switcher {
                margin-left: 40px;
            }
        }
    }

    .homepage {
        #main-component {
            .epc-page {
                .hp-top {
                    height: 707px;

                    .parallax-container {
                        height: 707px;
                    }

                    .container {
                        .text-cont {
                            max-width: 790px;
                            margin-top: 325px;
                            padding-left: 53px;
                        }
                    }
                }

                .hp-history {
                    >.container {

                        .first-row,
                        .last-row {
                            .inline-block {
                                overflow: hidden;

                                &:nth-child(2) {
                                    .inner-cont {
                                        padding: 10px 20px;

                                        h4 {
                                            font-size: 31px;
                                            margin-bottom: 10px;
                                            padding-bottom: 7px;
                                        }

                                        p {
                                            font-size: 14px;
                                            line-height: 15px;
                                        }

                                        a {
                                            font-size: 14px;
                                            margin-top: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .hp-sites {
        >.inline-block {
            figure {
                margin-bottom: 20px $i;

                &:first-of-type {
                    img {
                        max-width: 150px;
                    }
                }

                &:nth-of-type(2) {
                    img {
                        max-width: 130px;
                    }
                }
            }

            h4 {
                font-size: 23px $i;
                margin-bottom: 20px $i;
            }

            p {
                &:not(.readmore) {
                    font-size: 16px;
                    padding: 6px;
                }

                &.readmore {
                    font-size: 22px;
                    padding: 8px 0;
                }

                strong {
                    margin: 20px 0 $i;
                }
            }
        }
    }

    body.the-group {
        #main-component {
            .epc-page {
                .content {
                    >.container {
                        .management-section {
                            .peoples-wrapper {
                                >.inline-block {
                                    figure {
                                        img {
                                            max-width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    body.history-page {
        #main-component {
            .epc-page {
                .top {
                    .parallax-container+.container {
                        .collapsible-wrapper {
                            .single-history {
                                li {
                                    .collapsible-body {
                                        figure {
                                            figcaption {
                                                padding: 0px 0px 0px 10px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    body.contact-us {
        .sites-contact-info {
            >span {
                font-size: 12px;
            }

            *:not(:last-child) {
                padding-left: 10px;
            }
        }
    }

    body {
        >.hp-verticals {
            >.container {
                >.row {
                    .col-sm-6 {
                        overflow: hidden;
                    }

                    .col-sm-6.namer {
                        a {
                            span {
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
        }
    }

    .rtl {
        &.history-page {
            #main-component {
                .epc-page {
                    .top {
                        .parallax-container {
                            +.container {

                                .collapsible-wrapper,
                                .top-titles {
                                    max-width: 820px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .homepage {
        #main-component {
            .epc-page {
                .hp-top {
                    height: 607px;

                    .parallax-container {
                        height: 607px;
                    }

                    .container {
                        .text-cont {
                            max-width: 540px;
                            margin-top: 225px;
                            padding-left: 33px;
                            padding-top: 33px;
                            padding-bottom: 33px;

                            h1 {
                                font-size: 35px;
                                line-height: 44px;
                            }
                        }
                    }
                }

                .hp-history {
                    >.container {

                        .first-row,
                        .last-row {
                            .inline-block {
                                overflow: hidden;

                                &:nth-child(2) {
                                    overflow: inherit $i;

                                    .inner-cont {
                                        padding: 2px 20px $i;
                                        position: absolute $i;
                                        width: 500px $i;
                                        z-index: 2;
                                        background: #fff;

                                        h4 {
                                            font-size: 31px;
                                            margin-bottom: 10px;
                                            padding-bottom: 7px;
                                        }

                                        p {
                                            font-size: 14px;
                                            line-height: 15px;
                                        }

                                        a {
                                            font-size: 14px;
                                            margin-top: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .hp-counter {
                    >.container {
                        h3 {
                            margin: 70px 0 50px;
                            font-size: 29px;
                        }

                        .counters-wrapper {
                            .inline-block {
                                margin-right: -4px;

                                .counter {
                                    font-size: 50px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .hp-sites {
        >.inline-block {
            width: 33% $i;
        }
    }

    #header {
        .logo {
            margin-right: 16px;
            margin-left: 16px;
        }

        .phone {
            display: none;
        }
    }

    #footer {
        >.container {
            .first-row {
                display: none;
            }

            .last-row {
                margin-top: 0;

                >div {
                    width: 100%;
                    padding: 0 $i;
                    float: none;
                    text-align: center;
                }

                .theguy {
                    text-align: center;
                }
            }
        }
    }

    body.the-group {
        #main-component {
            .epc-page {
                .top {
                    .parallax-container {
                        .parallax {
                            img {
                                bottom: 0;
                            }
                        }
                    }
                }

                .content {
                    >.container {
                        .management-section {
                            .peoples-wrapper {
                                text-align: center;

                                >.inline-block {
                                    width: 33%;
                                    margin-bottom: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    body.contact-us {
        #main-component {
            >.container {
                >.epc-page {
                    >.contact-row {
                        >.row {
                            .col-sm-9 {
                                max-width: 70%;

                                .contact-form {
                                    .fox-container {
                                        .fox-column.fox-column6 {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #offcanvas {
        .phone.inline-block {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 30px;
            background: #fff;
            padding: 6px;
            margin-top: 20px;

            figure {
                a {
                    color: #747474;

                    img,
                    figcaption {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    .rtl {
        &.history-page {
            #main-component {
                .epc-page {
                    .top {
                        .parallax-container {
                            +.container {

                                .collapsible-wrapper,
                                .top-titles {
                                    max-width: 620px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    #header {
        .inline-block.language-switcher {
            margin-left: 10px;
            display: none;
        }
    }

    .homepage {
        #main-component {
            .epc-page {
                .hp-top {
                    height: calc(100vh - 72px);

                    .parallax-container {
                        height: calc(100vh - 72px);
                    }

                    .container .text-cont {
                        max-width: 100%;
                        margin-top: 225px;
                        padding: 20px;
                        margin: 0;
                        margin-top: calc(100vh - 60%);

                        h1 {
                            font-size: 25px;
                            line-height: 34px;
                        }
                    }
                }

                .hp-history {
                    >.container {

                        .first-row,
                        .last-row {
                            .inline-block {
                                overflow: hidden;

                                &:nth-child(1) {
                                    max-width: 40%;
                                    height: 212px $i;

                                    figure,
                                    img {
                                        height: 100%;
                                    }
                                }

                                &:nth-child(2) {
                                    overflow: hidden $i;
                                    max-width: 60%;
                                    height: 212px $i;

                                    .inner-cont {
                                        padding: 2px 20px $i;
                                        position: relative $i;
                                        width: auto $i;
                                        z-index: 2;
                                        background: #fff;

                                        h4 {
                                            font-size: 31px;
                                            margin-bottom: 10px;
                                            padding-bottom: 7px;
                                        }

                                        p {
                                            font-size: 14px;
                                            line-height: 15px;
                                        }

                                        a {
                                            font-size: 14px;
                                            margin-top: 10px;
                                        }
                                    }
                                }

                                &:nth-child(3) {
                                    max-width: 100%;
                                    height: auto;
                                    margin-top: 6px;

                                    img {
                                        width: 100%;
                                        height: auto;
                                    }
                                }
                            }
                        }

                        .last-row {
                            display: table $i;
                            position: relative;

                            .inline-block {
                                &:nth-child(1) {
                                    display: table-footer-group $i;
                                    max-width: 100% $i;
                                    height: auto $i;

                                    figure {
                                        height: 280px;
                                        margin-top: 6px;
                                    }

                                    img {
                                        width: 100%;
                                    }
                                }

                                &:nth-child(3) {
                                    max-width: 40% $i;
                                    height: 212px $i;

                                    figure,
                                    img {
                                        height: 100% $i;
                                    }
                                }
                            }
                        }
                    }
                }

                .hp-counter {
                    margin-bottom: 40px;

                    >.container {
                        h3 {
                            margin: 30px 0px;
                        }

                        .counters-wrapper {
                            .inline-block {
                                width: 100%;
                                height: auto;
                                padding: 30px 0 40px;
                                border-bottom: 2px solid #808183;
                                border-top: 0;
                                border-left: 0;
                                border-right: 0 $i;

                                &:first-child {
                                    border-top: 2px solid #808183;
                                }

                                h4 {
                                    margin-bottom: 17px;
                                }

                                .counter {
                                    font-size: 70px;
                                }
                            }
                        }

                        a {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .hp-sites {
        >.inline-block {
            width: 49% $i;
        }
    }

    body.the-group {
        #main-component {
            .epc-page {
                .top {
                    .parallax-container {
                        height: 215px;

                        img {
                            bottom: 0 $i;
                        }
                    }
                }

                .content {
                    >.container {
                        .management-section {
                            .peoples-wrapper {
                                >.inline-block {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    body.history-page {
        #main-component {
            .epc-page {
                .top {
                    .parallax-container {
                        height: 598px;

                        +.container {
                            .collapsible-wrapper {
                                max-width: 340px;
                                height: 400px $i;

                                .single-history {
                                    li {
                                        .collapsible-header {
                                            max-width: 130px;
                                            font-size: 18px;
                                            height: 50px;
                                            margin-right: 53px;

                                            &:before {
                                                right: -53px;
                                                width: 48px;
                                                height: 50px;
                                                background-size: 100%;
                                            }
                                        }

                                        .collapsible-body {
                                            figure {
                                                figcaption {
                                                    padding: 10px;
                                                    max-width: 100%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    body.contact-us {
        #main-component {
            >.container {
                >.epc-page {
                    >.contact-row {
                        >.row {
                            .col-sm-9 {
                                max-width: 100%;
                                margin-bottom: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

    #offcanvas {
        .container {
            .language-switcher {
                display: block;
                margin-bottom: 20px;

                li {
                    margin: 0;
                    display: inline-block;
                    vertical-align: top;
                    width: 49%;
                    text-align: center;

                    &.lang-active {
                        background-color: #595959;

                        a {
                            color: #fff;
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .rtl {
        &.history-page {
            #main-component {
                .epc-page {
                    .top {
                        .parallax-container {
                            height: 458px;

                            +.container {
                                .collapsible-wrapper {
                                    height: 300px $i;
                                }

                                .collapsible-wrapper,
                                .top-titles {
                                    max-width: 380px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .homepage {
        #bottom-comps {
            display: none;
        }
    }

    .side-icons {
        display: none;
    }

    .hp-verticals {
        >.container {
            >.row {
                width: 100% $i;

                .col-sm-6 {
                    float: inherit $i;

                    &.imager {
                        display: none;
                    }

                    &.namer {
                        height: 100px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: 468px) {
    #header {
        .language-switcher {
            display: none;
        }
    }

    .homepage {
        #main-component {
            .epc-page {
                .hp-top {
                    .container {
                        background-image: none;

                        .text-cont {
                            margin-top: calc(100vh - 100%);

                            h1 {
                                font-size: 20px;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

    #offcanvas {
        .phone.inline-block {
            font-size: 25px;
        }
    }

    .hp-sites {
        >.inline-block {
            width: 100% $i;
        }
    }

    .homepage {
        #main-component {
            .epc-page {
                .hp-history {
                    >.container {

                        .first-row,
                        .last-row {
                            .inline-block {
                                &:nth-child(2) {
                                    .inner-cont {
                                        p {
                                            font-size: 12px;
                                            line-height: 11px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .side-icons {
        position: absolute;
    }

    body.the-group {
        #main-component {
            .epc-page {
                .content {
                    >.container {
                        .management-section {
                            .peoples-wrapper {
                                >.inline-block {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .side-icons {
            top: 154px;
        }
    }

    body.history-page {
        #main-component {
            .epc-page {
                .top {
                    .parallax-container {
                        +.container {
                            .top-titles {
                                >div {
                                    text-align: left;
                                }

                                h1 {
                                    margin-top: 20px;
                                }
                            }

                            .collapsible-wrapper {
                                padding-right: 60px;
                            }
                        }
                    }
                }
            }
        }
    }

    body.contact-us {
        .side-iso {
            display: none;
        }
    }

    .rtl {
        &.history-page {
            #main-component {
                .epc-page {
                    .top {
                        .parallax-container {
                            height: 518px;
                        }
                    }
                }
            }
        }
    }
}

/*=====  End of Responsive  ======*/